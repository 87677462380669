var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('apexchart', {
    attrs: {
      "height": "360",
      "width": "100%",
      "type": "bar",
      "options": _vm.options || {},
      "series": _vm.series
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };