var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main_container"
  }, [_vm.fetchingData ? _c('div', {
    staticClass: "row pt-5"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.fetchingData,
      expression: "fetchingData"
    }],
    staticClass: "col-md-12"
  })]) : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "reporting mnotify-card"
  }, [_c('div', {
    staticClass: "mnotify-card-header d-flex justify-content-between align-items-center"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text"
  }, [_vm._v(" " + _vm._s(_vm.logintype == "sms" ? "SMS" : "Voice") + " " + _vm._s(_vm.$t("dashboard.campaign_history.title")) + " ")])]), _vm.loading ? _c('div', {}, [_c('div', {
    staticClass: "row pt-5"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "col-md-12"
  })])]) : _c('div', {}, [_c('div', {
    staticClass: "__reporting__table"
  }, [_c('div', {
    staticClass: "contact_table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    ref: "singleTable",
    staticClass: "main-table",
    staticStyle: {
      "width": "100vw",
      "font-size": "12px"
    },
    attrs: {
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.1)'
      },
      "data": _vm.pagedTableData,
      "row-style": _vm.handleRowStyle,
      "height": "60vh"
    },
    on: {
      "row-click": _vm.openCampaignDetails
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.campaign_history.button_history.name'),
      "prop": "campaign_name",
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "notranslate"
        }, [_vm._v(_vm._s(scope.row.campaign_name))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.campaign_history.total_recipients'),
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.total_recipients ? scope.row.total_recipients : scope.row.contacts) + " ")];
      }
    }])
  }), _vm.logintype == 'sms' ? _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.overview.sender_id'),
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "notranslate"
        }, [_vm._v(" " + _vm._s(scope.row.sender_id) + " ")])];
      }
    }], null, false, 3141911262)
  }) : _vm._e(), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.campaign_history.label'),
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.moment(scope.row.created_at).format("llll")) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.campaign_history.total_credit'),
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.total_sms_used || scope.row.total_voice_used) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.campaign_history.wallet_balance'),
      "width": _vm.customWidth
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.wallet_charge ? scope.row.wallet_charge : 0) + " ")];
      }
    }])
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)])])])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };