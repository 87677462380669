import "core-js/modules/es.array.push.js";
import RecentSMSChart from "./reporting_sms";
import BillingHistoryChart from "../main/BillingHistoryChart.vue";
import store from "@/state/store.js";
import moment from "moment";
import loginType from "@/helpers/loginType";
export default {
  components: {
    RecentSMSChart,
    BillingHistoryChart
  },
  data() {
    return {
      Campaign: "",
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      campaignHistory: [],
      tableLoading: false
    };
  },
  methods: {
    handleRowStyle({
      row,
      rowIndex
    }) {
      return this.campaignHistory && this.campaignHistory[rowIndex].active ? {
        background: "rgba(207, 214, 233, 0.19)"
      } : "";
    },
    handleCurrentChange(val) {
      this.fetchingCampaign(val);
      this.page = val;
    },
    openCampaignDetails(data) {
      let total = data.credit_used ? data.credit_used : data.total_sms_used;
      store.dispatch("reporting/fetchReportingTotalCredit", total);
      if (loginType() === "sms") {
        this.$router.push(`/sms/campaign/${data.campaign_id}/details`).catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      } else {
        this.$router.push(`/voice/campaign/${data.campaign_id}/details`).catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
    },
    fetchingCampaign(whichpage = 1) {
      this.tableLoading = true;
      store.dispatch("reporting/fetchSMSHistory", whichpage).then(res => {
        this.campaignHistory = res.data;
      }).finally(() => this.tableLoading = false);
    }
  },
  mounted() {
    // this.fetchingCampaign()
    this.tableLoading = true;
    store.dispatch("reporting/fetchSMSHistory", 1).then(res => {
      this.campaignHistory = res.data;
      this.total = res.total;
    }).finally(() => this.tableLoading = false);
  },
  computed: {
    pagedTableData() {
      return this.campaignHistory;
    },
    logintype() {
      return loginType();
    },
    // campaignHistory(){
    // 	return store.getters['reporting/campaignHistory']
    // },
    fetchingData() {
      return store.getters["reporting/fetchingData"];
    },
    campaignLabels() {
      return store.getters["reporting/campaignLabels"];
    },
    campaignSeries() {
      return store.getters["reporting/campaignSeries"];
    },
    categories() {
      return store.getters["reporting/billingSumarryCategory"];
    },
    totalRecipients() {
      return store.getters["reporting/billingSumarryRecipients"];
    },
    customWidth() {
      if (screen.width < 768) {
        return "150px";
      } else {
        return "min-content";
      }
    }
  }
};